import Product from './Product';
function ProductsSection() {
  return (
    <div className='container mt-5'>
          <div className='row  justify-content-center text-center pt-3'>
            <div className='col-12 col-md-8 col-lg-9 landing_h3 text-center' id="productos">Productos</div>
          </div>
          <div className='container me-lg-0 my-5 ms-lg-auto'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-8'>
                <Product 
                  title='Papel para hornear y cocinar'
                  image='reynolds-papel-hornear'
                  text={`Antiadherente en ambas caras<br />
                  Fácilita la limpieza<br>
                  Apto para horno<br>
                  ¡Cocinar nunca fue tan práctico!`}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Papel aluminio'
                  image='reynolds-papel-aluminio'
                  text='Producto de aluminio versátil que proporciona resistencia y durabilidad superiores. ¡Es ideal para envolver, cocinar, hornear, asar, cubrir y más!'
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Plástico adherente'
                  image='reynolds-plastico-adherente'
                  text='Con este film transparente, los restos de comida, como frutas y verduras, se pueden envolver herméticamente, alargando su vida útil y ahorrándote tiempo y dinero. Sella el sabor del interior y retiene el aire y la humedad.'
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Bolsas ultra resistentes'
                  image='reynolds-bolsas'
                  text={`Disponibles en cinco tamaños diferentes: SÁNDWICH (PEQUEÑO)| ALMACENAMIENTO MEDIANO Y GRANDE | CONGELADOR MEDIANO Y GRANDE. Todas nuestras bolsas tienen una cremallera resellable duradera que se puede abrir y cerrar muchas veces, y son reutilizables.`}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Bandeja cuadrada'
                  image='reynolds-bandeja-cuadrada'
                  text={`Bandeja perfecta para hornito tostador<br>
                  Presentación cuadrada 20 x 20 x 4,6cm<br>
                  1 unidad<br>
                  ¡Lista para cocinar tus platillos favoritos!`}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Bandeja rectangular'
                  image='reynolds-bandeja-rectangular'
                  text={`Bandeja perfecta para hornito tostador<br>
                  Presentación cuadrada 30.5 x 23 x 6.4cm<br>
                  1 unidad<br>
                  A disfrutar tus mejores creaciones!`}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                <Product 
                  title='Bandeja circular'
                  image='reynolds-bandeja-circular'
                  text={`Bandeja perfecta para hornito tostador.<br>
                  Presentación redonda 22.2 x 2.8 cm<br>
                  1 unidad<br>
                  ¡Crea tus mejores postres!`}
                />
              </div>
            </div>
          </div>
        </div>
  );
}

export default ProductsSection;
