import Footer from './components/Footer';
import Header from './components/Header';
import './App.css';
import TopSection from './components/TopSection';
import MiddleSection from './components/MiddleSection';
import WhereBuy from './components/WhereBuy';
import ProductsSection from './components/ProductsSection';
const landingImages = require.context('./assets/images', true);
function App() {
  return (
    <div className="App">
      <Header />
      <TopSection />
      <MiddleSection />
      <ProductsSection />
      <WhereBuy/>
      <Footer />
    </div>
  );
}

export default App;
