import React from 'react'
import { Link } from 'react-router-dom';
const landingImages = require.context('../assets/images', true);
function Store({logo, title, handle, disabled}) {
  return (
    <div className='store'>
      <div className='logostore'>
        <img src={landingImages(`./${logo}`)} alt={title} className="logo"/>
      </div>
      <div className='landing_h6 text-center py-2'>{title}</div>
      <div className='bt'>
        { disabled ? <div className='wherebuttomdisable'>AGOTADO</div> : <div className='wherebuttom'>
          <Link to={{}} onClick={handle}>COMPRAR</Link>
        </div>}
      </div>
    </div>
  );
}

export default Store;
