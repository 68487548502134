import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalComponent from './ModalComponent';
const landingImages = require.context('../assets/images', true);
function Header() {
  const [menuOpen, setMenuOpen] = useState(false);  
  const [showModal, setShowModal] = useState(false);
  const handleGoStore = (url, name, e) => {
    e.preventDefault();
    window.dataLayer.push({
      event: "store",
      button_title: name
    });
    window.open(url, '_blank')
  }
  // Función mostrar modal
  const handleOpenModal = (e) => {
    e.preventDefault();
    setShowModal(true);    
    window.dataLayer.push({
      event: "action",
      button_title: 'Donde comprar'
    });
  }
  // Función cerrar modal
  const handleCloseModal = () => {
      setShowModal(false);
  }
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <><ModalComponent showModal={showModal} handleCloseModal={handleCloseModal} handleGoStore={handleGoStore} />
    <header className={`header ${menuOpen ? 'menu-open position-relative' : ''}`}>
      <div className="header-content">
        <div className="logo">
          <a href="/"><img src={landingImages(`./Reynolds_logo_inverse.svg`)} alt="Reynolds" className="logo"/></a>
        </div>
        <div className="nav-hamburger">
          <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span className="menu-icon"></span>
          </button>
        </div>
        <nav className={`menu ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#quienessomos">Quiénes somos</a></li>
            <li><a href="#productos">Productos</a></li>
            <li><a href="#dondecomprar">Dónde comprar</a></li>
          </ul>
        </nav>
        { menuOpen && <div className='wheretobuy'>
          <Link to={{}} onClick={handleOpenModal}>
            DÓNDE COMPRAR <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path d="M27.5 7.49998H22.9788C22.8468 5.87232 22.1071 4.35396 20.9066 3.24688C19.7062 2.13981 18.133 1.52515 16.5 1.52515C14.867 1.52515 13.2938 2.13981 12.0934 3.24688C10.8929 4.35396 10.1532 5.87232 10.0212 7.49998H5.5C4.83696 7.49998 4.20107 7.76337 3.73223 8.23221C3.26339 8.70106 3 9.33694 3 9.99998V25C3 25.663 3.26339 26.2989 3.73223 26.7677C4.20107 27.2366 4.83696 27.5 5.5 27.5H27.5C28.163 27.5 28.7989 27.2366 29.2678 26.7677C29.7366 26.2989 30 25.663 30 25V9.99998C30 9.33694 29.7366 8.70106 29.2678 8.23221C28.7989 7.76337 28.163 7.49998 27.5 7.49998ZM16.5 4.49998C17.3416 4.50007 18.1549 4.80338 18.7911 5.35435C19.4272 5.90531 19.8435 6.66704 19.9638 7.49998H13.0363C13.1565 6.66704 13.5728 5.90531 14.2089 5.35435C14.8451 4.80338 15.6584 4.50007 16.5 4.49998ZM27 24.5H6V10.5H10V12C10 12.3978 10.158 12.7793 10.4393 13.0606C10.7206 13.3419 11.1022 13.5 11.5 13.5C11.8978 13.5 12.2794 13.3419 12.5607 13.0606C12.842 12.7793 13 12.3978 13 12V10.5H20V12C20 12.3978 20.158 12.7793 20.4393 13.0606C20.7206 13.3419 21.1022 13.5 21.5 13.5C21.8978 13.5 22.2794 13.3419 22.5607 13.0606C22.842 12.7793 23 12.3978 23 12V10.5H27V24.5Z" fill="#001489"/></svg> 
          </Link>
        </div> }
      </div>
    </header>
    </>
  );
}
export default Header;
